@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  max-width: 1550px;
  margin: 0 auto;
}

.container_part_two {
  max-width: 1300px;
  margin: 0 auto;
}

