dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    border: none;
    color: #757575;
    font-size: 16px;
    cursor: pointer;
    padding: 0.7rem 0.3rem 0.7rem 0.7rem; 
   }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: -5px;
    background-color: #ffff;
    box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    z-index: 1;
    min-width: 140px;
    padding: .4rem .4rem .4rem 1rem;
  }
  
  .dropdown-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .dropdown-menu li {
    padding: 3px 0px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .dropdown-menu li:hover {
    color: #BCA44E;
  }